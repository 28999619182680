<template>
  <div class="content">
    <div class="content_box beforetab" id="working-progress-box">
      <form id="searchForm" @submit.prevent>
        <table class="tbl_search">
          <colgroup>
            <col width="60px">
            <col width="150px">
            <col width="150px">
            <col width="260px">
            <col width="*">
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONEX010T010.001') }}<!-- 조회 --></th>
              <td>
                <input v-model="searchParams.workType" type="radio" id="workType_E" name="workType" value="E">
                <label for="workType_E" class="mr10"><span></span>{{ $t('msg.ONEX010T010.002') }}<!-- 수출 --></label>
                <input v-model="searchParams.workType" type="radio" id="workType_I" name="workType" value="I">
                <label for="workType_I"><span></span>{{ $t('msg.ONEX010T010.003') }}<!-- 수입 --></label>
              </td>
              <td class="pr5">
                <select v-model="searchParams.kind" id="kind" @change="checkSearchValidation('kind')">
                  <option value="">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
                  <option v-for="(cvo, idx) in searchKindList" :key="'kind' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td class="text_left pl0">
                <div v-if="searchParams.kind == '02'">
                  <input type="text" id="keyword"
                         v-model="searchParams.keyword"
                         :placeholder="placeHolder"
                         @keyup="checkSearchValidation('keyword')"
                         @keypress.enter="search()">
                </div>
                <div v-else class="form_wrap t2">
                  <e-date-range-picker-simple
                    id="fromDtToDt"
                    :sdate="searchParams.fromDt"
                    :edate="searchParams.toDt"
                    :is-debug="true"
                    @change="changeDateRange"
                  />
                </div>
              </td>
              <td>
                <span>
                  <input type="radio" id="profileYn_Y" name="profileYn" v-model="searchParams.profileYn" value="Y">
                  <label for="profileYn_Y" class="mr10"><span></span>{{ $t('msg.ONEX010T010.006') }}<!-- My Shipments --></label>
                  <span class="position_relative mr20">
                    <button type="button" class="tbl_icon help" @click="showHelp01 = !showHelp01">help</button>
                    <div v-show="showHelp01" class="tooltip_wrap short position_absolute" style="width:180px; left:0; top:24px;">
                      <div class="cont">
                        <button type="button" class="close" @click="showHelp01 = false">close</button>
                        <ul class="bul_list_sm t2">
                          <li v-html="$t('msg.ONEX010T010.007')"><!-- Profile에 해당하는<br />리스트를 볼 수 있습니다 --></li>
                        </ul>
                      </div>
                    </div>
                  </span>
                  <input type="radio" id="profileYn_N" name="profileYn" v-model="searchParams.profileYn" value="N">
                  <label for="profileYn_N" class="mr10"><span></span>{{ $t('msg.ONEX010T010.008') }}<!-- All Shipments --></label>
                  <span class="position_relative">
                    <button type="button" class="tbl_icon help" @click="showHelp02 = !showHelp02">help</button>
                    <div v-show="showHelp02" class="tooltip_wrap short position_absolute" style="width:200px; left:0; top:24px;">
                      <div class="cont">
                        <button type="button" class="close" @click="showHelp02 = false">close</button>
                        <ul class="bul_list_sm t2">
                          <li v-html="$t('msg.ONEX010T010.009')"><!-- Profile의 소속 기업에 해당하는 <br> 리스트를 볼 수 있습니다. --></li>
                        </ul>
                      </div>
                    </div>
                  </span>
                </span>
              </td>
              <td class="text_right">
                <a class="button blue sh" href="#" @click.prevent="search()">{{ $t('msg.ONEX010T010.010') }}<!-- 검색 --></a>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>

    <div v-if="localYn == 'Y'">
      <a class="button blue sh mt5 mb5" href="#" @click.prevent="() => { isSearchDetail = !isSearchDetail }">상세검색</a>
      <div v-if="isSearchDetail" class="content_box">
        <input type="checkbox" id="checkbox_close" v-model="searchParams.docClose24Yn" true-value="Y" false-value="N">
        <label for="checkbox_close" class="mr20"><span></span>서류마감 24시간 이내</label>
        <div>
          <ul class="ul_box">
            <li>미처리 건</li>
            <li v-for="(vo, idx) in tempList01" :key="'tempList01_' + idx">
              <input type="checkbox" :id="'checkbox_01_' + idx" v-model="searchParams.arrUnsolvedSt" :value="vo.cd">
              <label :for="'checkbox_01_' + idx" class="mr20"><span></span>{{ vo.cdNm }}</label>
            </li>
          </ul>
          <ul class="ul_box">
            <li>스케줄 변동</li>
            <li v-for="(vo, idx) in tempList02" :key="'tempList02_' + idx">
              <input type="checkbox" :id="'checkbox_02_' + idx" v-model="searchParams.arrChgSch" :value="vo.cd">
              <label :for="'checkbox_02_' + idx" class="mr20"><span></span>{{ vo.cdNm }}</label>
            </li>
          </ul>
          <ul class="ul_box">
            <li>Booking 상태</li>
            <li v-for="(vo, idx) in tempList03" :key="'tempList03_' + idx">
              <input type="checkbox" :id="'checkbox_03_' + idx" v-model="searchParams.arrBkgSt" :value="vo.cd">
              <label :for="'checkbox_03_' + idx" class="mr20"><span></span>{{ vo.cdNm }}</label>
            </li>
          </ul>
          <ul class="ul_box">
            <li>B/L 상태</li>
            <li v-for="(vo, idx) in tempList04" :key="'tempList04_' + idx">
              <input type="checkbox" :id="'checkbox_04_' + idx" v-model="searchParams.arrIssueSt" :value="vo.cd">
              <label :for="'checkbox_04_' + idx" class="mr20"><span></span>{{ vo.cdNm }}</label>
            </li>
          </ul>
          <ul class="ul_box">
            <li>운임</li>
            <li v-for="(vo, idx) in tempList05" :key="'tempList05_' + idx">
              <input type="checkbox" :id="'checkbox_05_' + idx" v-model="searchParams.arrFrtSt" :value="vo.cd">
              <label :for="'checkbox_05_' + idx" class="mr20"><span></span>{{ vo.cdNm }}</label>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <working-progress-export ref="wpExport" v-if="searchParams.workType === 'E'" :parent-info="wpExportInitParams"/>
    <working-progress-import ref="wpImport" v-if="searchParams.workType === 'I'" :parent-info="wpImportInitParams"/>

  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'

export default {
  name: 'WorkingProgress',
  components: {
    WorkingProgressExport: () => import('@/pages/trans/WorkingProgressExport'),
    WorkingProgressImport: () => import('@/pages/trans/WorkingProgressImport'),
    EDateRangePickerSimple: () => import('@/components/common/EDateRangePickerSimple')
  },
  data () {
    return {
      showHelp01: false,
      showHelp02: false,
      searchKindList: [
        { cd: '03', cdNm: 'On Board Date' },
        { cd: '01', cdNm: 'Booking Date' },
        { cd: '02', cdNm: 'Booking No' }
      ],
      wpExportInitParams: {},
      wpImportInitParams: {},
      searchParams: {
        workType: 'E', // 업무구분 (수출: E, 수입: I)
        kind: '01', // 검색구분 (Booking Date: 01, bkgNo/BlNo: 02, OnBoardDate: 03)
        fromDt: '',
        toDt: '',
        keyword: '',
        profileYn: 'Y',
        docClose24Yn: 'N',
        arrUnsolvedSt: [],
        arrChgSch: [],
        arrBkgSt: [],
        arrIssueSt: [],
        arrFrtSt: []
      },
      // import
      searchKindListEx: [
        { cd: '03', cdNm: 'On Board Date' },
        { cd: '01', cdNm: 'Booking Date' },
        { cd: '02', cdNm: 'Booking No' }
      ],
      searchKindListIm: [
        { cd: '01', cdNm: '입항일' },
        { cd: '02', cdNm: 'B/L No' }
      ],
      placeHolder: this.$t('msg.ONEX010T010.005'),
      localYn: 'N',
      isSearchDetail: false,
      tempList01: [
        { cd: 'UNS01', cdNm: '미정산' },
        { cd: 'UNS02', cdNm: '미발행' },
        { cd: 'UNS03', cdNm: '미반입' },
        { cd: 'UNS04', cdNm: '미선적' },
        { cd: 'UNS05', cdNm: '미반출' }
      ],
      tempList02: [
        { cd: 'SCH01', cdNm: '선적지 지연' },
        { cd: 'SCH02', cdNm: '양하지 지연' },
        { cd: 'SCH03', cdNm: '선박교체' }
      ],
      tempList03: [
        { cd: 'BKG01', cdNm: '승인' },
        { cd: 'BKG02', cdNm: '미승인' }
      ],
      tempList04: [
        { cd: 'ISS01', cdNm: '발행가능' },
        { cd: 'ISS02', cdNm: '발행중' },
        { cd: 'ISS03', cdNm: '발행불가' }
      ],
      tempList05: [
        { cd: 'FRT01', cdNm: '운임 미등록' },
        { cd: 'FRT02', cdNm: '운임 미정산' }
      ]
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this._ = require('lodash')
    this.localYn = process.env.VUE_APP_MODE === 'LOCAL' ? 'Y' : 'N'
  },
  watch: {
    'searchParams.workType': function (val) {
      if (val === 'E') {
        this.searchKindList = this.searchKindListEx
        this.placeHolder = this.$t('msg.ONEX010T010.005')
      } else {
        this.searchKindList = this.searchKindListIm
        this.placeHolder = this.$t('msg.ONEX010T010.075')
      }
    }
  },
  mounted () {
    const tempPrevData = sessionStorage.getItem('working-progress-prev-data')
    const prevData = tempPrevData === null || tempPrevData === undefined ? undefined : JSON.parse(tempPrevData)

    if (prevData !== undefined) {
      this.searchParams = this._.cloneDeep(prevData.searchParams)
      this.searchAct()
    } else {
      const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'W', startGap: 1, endCode: 'M', endGap: 1 })
      this.searchParams.fromDt = arrMonthInfo.stDt
      this.searchParams.toDt = arrMonthInfo.enDt
    }
  },
  methods: {
    search () {
      if (!this.checkSearchValidationAll()) {
        return
      }

      this.searchAct()
    },
    searchAct () {
      if (this.searchParams.workType === 'E') {
        if (this.$refs.wpExport === undefined) {
          this.wpExportInitParams = this._.cloneDeep(this.searchParams)
        } else {
          this.$refs.wpExport.search(this.searchParams)
        }
      } else if (this.searchParams.workType === 'I') {
        if (this.$refs.wpImport === undefined) {
          this.wpImportInitParams = this._.cloneDeep(this.searchParams)
        } else {
          this.$refs.wpImport.search(this.searchParams)
        }
      }
    },
    changeDateRange (s, e) {
      this.searchParams.fromDt = s
      this.searchParams.toDt = e

      this.checkSearchValidation('fromDtToDt')
    },
    checkSearchValidationAll () {
      let isOk = true
      const prefix = ['kind', 'fromDtToDt', 'keyword']

      prefix.forEach(el => {
        if (!this.checkSearchValidation(el)) {
          isOk = false
        }
      })

      return isOk
    },
    checkSearchValidation (id) {
      const searchParams = this.searchParams
      const frm = document.querySelector('#searchForm')
      const selector = frm.querySelector('#' + id)
      let isOk = true

      this.$ekmtcCommon.hideErrorTooltip(selector)

      switch (id) {
        case 'kind':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
          break
        case 'fromDtToDt':
          if (searchParams.kind !== '02' && (this.$ekmtcCommon.isEmpty(searchParams.fromDt) || this.$ekmtcCommon.isEmpty(searchParams.toDt))) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
          break
        case 'keyword':
          if (searchParams.kind === '02' && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
          break
      }
      return isOk
    }
  }
}

</script>

<style scoped>
.ul_box {
  overflow: hidden;
}

.ul_box li {
  float: left;
  padding-left: 10px;
  width: 150px !important;
  height: 30px;
  line-height: 30px;
}

</style>
